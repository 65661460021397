import {HTTP} from '../utils/axios'

class BlocklistModel extends HTTP {
  /*首页渲染*/
  index(page = 1, index = 1) {
      return this.request({
          url: 'board/block_apps?id=' + index,
          method: "GET",
          data: {
            page: page
          }
      })
  }

  /*增加*/
  create(value) {
    return this.request({
        url: "board/block_apps",
        method: "POST",
        data: value,
    })
  }

  /*修改*/
  update(value, id) {
    return this.request({
        url: "board/block_apps/" + id,
        method: "PATCH",
        data: value,
    })
  }

  /*删除*/
  destroy(id) {
    return this.request({
        url: 'board/block_apps/' + id,
        method: "DELETE",
    })
  }

  /*置顶*/
  set_top(value) {
    return this.request({
        url: 'board/block_apps/set_top',
        method: "PUT",
        data: value
    })
  }

  /*后移*/
  move_next(value) {
    return this.request({
        url: 'board/block_apps/move_next',
        method: "PUT",
        data: value
    })
  }
}

export {BlocklistModel}