<template>
  <div>
    <div v-if="$route.params.id == 1">
      <a-form>
        <a-form-item>
          <a-button type="primary" class="new" @click="showModal('new')"
            >新增</a-button
          >
        </a-form-item>
      </a-form>
      <a-table
        :columns="columns"
        :dataSource="data"
        :pagination="false"
        :loading="loading"
      >
        <template slot="url" slot-scope="text">
          <a :href="text">{{ text }}</a>
        </template>
        <template slot="visible" slot-scope="text, record">
          <a-switch
            :default-checked="text"
            @change="handleVisiChange(record)"
          />
        </template>
        <span slot="action" slot-scope="text, record">
          <a href="javascript:;" @click="handleById(record)">编辑</a>
          <a-divider type="vertical" />
          <a href="javascript:;" @click="handleSetTop(record)" class="green"
            >置顶</a
          >
          <a-divider type="vertical" />
          <a href="javascript:;" @click="handleMoveNext(record)" class="green"
            >后移</a
          >
          <a-divider type="vertical" />
          <a href="javascript:;" @click="handleDelete(record)" class="delete"
            >删除</a
          >
        </span>
      </a-table>
      <Pagination
        :current="current"
        :total="total"
        @handleChange="handleChange"
        v-show="total > 20"
      />
      <a-modal
        :title="title"
        v-model="visible"
        width="450px"
        @ok="handleOk"
        @cancel="handleCancel"
        okText="确认"
        cancelText="取消"
      >
        <a-form
          :form="form2"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-form-item label="推荐软件">
            <a-input
              v-decorator="[
                'name',
                { rules: [{ required: true, message: '请填写软件名称！' }] },
              ]"
              autocomplete="off"
            >
            </a-input>
          </a-form-item>
          <a-form-item label="软件地址">
            <a-input v-decorator="['url', { rules: [] }]" autocomplete="off" />
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
    <div v-if="$route.params.id == 2">
      <a-table
        :columns="columns1"
        :dataSource="data"
        :pagination="false"
        :loading="loading"
      >
        <template slot="url" slot-scope="text">
          <a :href="text">{{ text }}</a>
        </template>
      </a-table>
      <Pagination
        :current="current"
        :total="total"
        @handleChange="handleChange"
        v-show="total > 20"
      />
    </div>
    <div v-if="$route.params.id == 3">
      <a-table
        :columns="columns2"
        :dataSource="data"
        :pagination="false"
        :loading="loading"
      >
        <template slot="url" slot-scope="text">
          <a :href="text">{{ text }}</a>
        </template>
      </a-table>
      <Pagination
        :current="current"
        :total="total"
        @handleChange="handleChange"
        v-show="total > 20"
      />
    </div>
  </div>
</template>

<script>
import { BlocklistModel } from "../../models/blocklist";
// import {SoftlistsModel} from '../../models/softlists'
import formatTime from "../../utils/formatTime";
import Pagination from "../../components/Pagination";
import { mapMutations } from "vuex";
const blocklistModel = new BlocklistModel();
// const softlistsModel = new SoftlistsModel()
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "链接地址",
    dataIndex: "url",
    key: "url",
    scopedSlots: { customRender: "url" },
  },
  {
    title: "是否显示",
    dataIndex: "visible",
    key: "visible",
    scopedSlots: { customRender: "visible" },
  },
  {
    title: "操作",
    dataIndex: "",
    key: "action",
    scopedSlots: {
      customRender: "action",
    },
  },
];
const columns1 = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "下载量",
    dataIndex: "download_num",
    key: "download_num",
  },
  {
    title: "点赞量",
    dataIndex: "zan_num",
    key: "zan_num",
  },
  {
    title: "链接地址",
    dataIndex: "url",
    key: "url",
    scopedSlots: { customRender: "url" },
  },
];
const columns2 = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "更新日期",
    dataIndex: "updated_at",
    key: "updated_at",
  },
  {
    title: "链接地址",
    dataIndex: "url",
    key: "url",
    scopedSlots: { customRender: "url" },
  },
];
export default {
  data() {
    return {
      form1: this.$form.createForm(this, {
        name: "horizontal_login",
      }),
      data: [],
      title: "",
      columns,
      columns1,
      columns2,
      visible: false,
      formLayout: "horizontal",
      form2: this.$form.createForm(this, { name: "coordinated" }),
      flag: "",
      editId: 0,
      loading: false,
      total: 0,
      current: 1,
    };
  },

  created() {
    if (this.$route.params["id"] == 1) {
      this.queryBlockList(1, this.$route.params["id"]);
    } else if (this.$route.params["id"] == 2) {
      this.queryBlockList(1, this.$route.params["id"]);
    } else if (this.$route.params["id"] == 3) {
      this.queryBlockList(1, this.$route.params["id"]);
    }
  },

  methods: {
    handleById(record, flag) {
      this.flag = flag;
      this.editId = record.id;
      this.title = "编辑";
      this.visible = true;
      this.$nextTick(() => {
        this.form2.setFieldsValue({
          name: record.name,
          url: record.url,
        });
      });
    },

    handleVisiChange(record) {
      blocklistModel
        .update({ visible: !record.visible }, record.id)
        .then((res) => {
          if (res.code == 0) {
            this.visible = false;
            this.$notification.open({
              message: "提示",
              description: res.message,
              duration: 2,
            });
            this.queryBlockList(1, this.$route.params["id"]);
          }
        });
    },
    handleDelete(record) {
      this.$confirm({
        content: "确定要删除吗？",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          blocklistModel.destroy(record.id).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.queryBlockList(1, this.$route.params["id"]);
            }
          });
        },
        onCancel() {},
      });
    },

    handleSetTop(record) {
      this.$confirm({
        content: "确定要置顶吗？",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          blocklistModel.set_top({ id: record.id }).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.queryBlockList(1, this.$route.params["id"]);
            }
          });
        },
        onCancel() {},
      });
    },

    handleMoveNext(record) {
      this.$confirm({
        content: "确定要后移吗？",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          blocklistModel.move_next({ id: record.id }).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.queryBlockList(1, this.$route.params["id"]);
            }
          });
        },
        onCancel() {},
      });
    },

    showModal(flag) {
      this.flag = flag;
      this.visible = true;
      this.title = "新增";
    },

    handleChange(page) {
      this.current = page;
      this.queryBlockList(page, this.$route.params["id"]);
    },

    handleOk(e) {
      e.preventDefault();
      this.form2.validateFields((err, values) => {
        if (!err) {
          values.bg_image = this.tempUrl;
          values.is_recommend = true;
          if (this.flag == "new") {
            blocklistModel.create(values).then((res) => {
              if (res.code == 0) {
                this.visible = false;
                this.$notification.open({
                  message: "提示",
                  description: res.message,
                  duration: 2,
                });
                this.queryBlockList(1, this.$route.params["id"]);
              }
            });
          } else {
            blocklistModel.update(values, this.editId).then((res) => {
              if (res.code == 0) {
                this.visible = false;
                this.$notification.open({
                  message: "提示",
                  description: res.message,
                  duration: 2,
                });
                this.queryBlockList(1, this.$route.params["id"]);
              }
            });
          }
          this.form2.resetFields();
        }
      });
    },

    handleCancel() {
      this.form2.resetFields();
    },

    queryBlockList(page, index) {
      this.loading = true;
      blocklistModel.index(page, index).then((res) => {
        console.log(res);
        if (res.code == 0) {
          res.result.map((item, index) => {
            item.key = index;
            item.updated_at = formatTime(item.updated_at);
            item.download_num = item.sum ? item.sum : 0;
            item.zan_num = item.count ? item.count : 0;
            if (this.$route.params.id == 1) {
              item.url = process.env.VUE_APP_PORT + "/apps/" + item.id;
            } else if (this.$route.params.id == 2) {
              item.url = process.env.VUE_APP_PORT + "/apps/" + item.id;
            } else if (this.$route.params.id == 3) {
              item.url = process.env.VUE_APP_PORT + "/apps/" + item.id;
            }
            return item;
          });
        }
        this.data = res.result;
        this.loading = false;
        this.total = res.total;
      });
    },

    ...mapMutations({
      parentName: "PARENT_NAME",
    }),
  },

  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
.new {
  float: right;
}
</style>
